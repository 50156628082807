import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import cx from 'classnames';
import './recommendation.scss';
import Badge from '../Badge';
import ReactMarkdown from 'react-markdown';
import { LangContext } from '../Layout';

const Recommandation = ({ recommandations }) => {
  const { language } = useContext(LangContext);

  return (
    <div className="recommendation" id={'recommendation'}>
      <div className="recommendation__top">
        <div className="recommendation__top__badge">
          <Badge text={recommandations['headline_' + language]} />
        </div>
        <div className="recommendation__top__title">
          {recommandations['title_' + language]}
        </div>
        <div className="recommendation__top__description">
          <ReactMarkdown source={recommandations['description_' + language]} />
        </div>
      </div>
      <div className="recommendation__comments">
        {recommandations.comment.length !== 0
          ? recommandations.comment.map((comment, index) => {
              return (
                <div
                  className={cx('recommendation__comments__comment', {
                    recommendation__comments__notLast:
                      index !== recommandations.comment.length - 1,
                  })}
                  key={index}
                >
                  <div className="recommendation__comments__comment__logo">
                    <img src={comment.logo.publicURL} alt={'logo'} />
                  </div>
                  <div className="recommendation__comments__comment__description">
                    <span>❝</span> {comment['description_' + language]}
                  </div>
                  <div className={'recommendation__comments__comment__author'}>
                    <Img
                      alt={comment.author}
                      fluid={comment.avatar.childImageSharp.fluid}
                      className={
                        'recommendation__comments__comment__author__avatar'
                      }
                    />

                    <div
                      className={
                        'recommendation__comments__comment__author__description'
                      }
                    >
                      <div
                        className={
                          'recommendation__comments__comment__author__description__name'
                        }
                      >
                        {comment.author}
                      </div>
                      <div
                        className={
                          'recommendation__comments__comment__author__description__position'
                        }
                      >
                        {comment['position_' + language]}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : ''}
      </div>
    </div>
  );
};
Recommandation.propTypes = {
  recommandations: PropTypes.object,
};
export default Recommandation;

import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';

function Badge({ text }) {
  return <div className="badge-content">{text}</div>;
}

Badge.propTypes = {
  text: PropTypes.string,
};
export default Badge;
